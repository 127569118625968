.hero {
  position: relative;
}

.hero:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;   
  opacity: 0.5;
  background: url("/img/hero.webp");
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  width:auto;
  height: 100%;
  filter: blur(1px);
}